<template>
	<v-dialog v-model="dialog" persistent max-width="600px">
		<v-card class="pa-6">
			<v-card-title class="d-flex justify-center mb-4">
				<span class="headline">{{ title }}</span>
			</v-card-title>
			<v-card-text>
				<FormRegisterAccount
					v-if="step === 1"
					@closeDialog="closeDialog"
					@register="data => register(data)"
				/>
				<!-- <FormActiveCode
					v-else-if="step === 2"
					@activeAccount="code => activeAccount(code)"
					@resendCode="resendCode"
				/> -->
				<FormUpdateDomain
					v-else-if="step === 3"
					@updateDomainInfo="data => updateDomainInfo(data)"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import FormUpdateDomain from '@/modules/Clients/components/FormUpdateDomain'
// import FormActiveCode from '@/modules/Clients/components/FormActiveCode'
import FormRegisterAccount from '@/modules/Clients/components/FormRegisterAccount'
import Base64 from '@/helpers/encodeBase64.js'
export default {
	components: {
		FormRegisterAccount,
		// FormActiveCode,
		FormUpdateDomain
	},
	data() {
		return {
			dialog: false,
			step: 1,
			listTitle: {
				1: 'Thông tin đăng ký tài khoản',
				2: 'Nhập mã kích hoạt',
				3: 'Nhập thông tin tên miền'
			}
		}
	},
	computed: {
		...mapGetters({
			newClient: 'client/getNewClient'
		}),
		title() {
			return this.listTitle[this.step]
		}
	},
	methods: {
		...mapActions({
			createNewClient: 'client/createNewClient',
			resendConfirmCode: 'client/resendConfirmCode',
			confirmCode: 'client/confirmCode',
			activeNewVendor: 'vendor/activeNewVendor'
		}),
		openDialog() {
			this.dialog = true
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
		async activeAccount(activeCode) {
			try {
				this.$message.loading('Đang kích hoạt trang...', 20000)
				await this.confirmCode({
					userId: this.newClient.id,
					vendorId: this.newClient.vendorId,
					code: activeCode
				})
				this.$message.success('Kích hoạt thành công')
				this.step = 3
			} catch (error) {
				this.$message.error(error)
			}
		},
		async resendCode() {
			try {
				this.$message.loading('Đang gửi lại mã kích hoạt...', 20000)
				await this.$confirm.show({
					title: 'Bạn có chắc muốn gửi lại mã kích hoạt?',
					text: 'Mã kích hoạt cũ sẽ bị vô hiệu hóa',
					done: async () => {
						await this.resendConfirmCode({
							userId: this.newClient.id,
							vendorId: this.newClient.vendorId
						})
					}
				})
				this.$message.success('Gửi lại mã kích hoạt thành công !')
			} catch (error) {
				this.$message.error(error)
			}
		},
		async updateDomainInfo(data) {
			try {
				this.$message.loading('Đang tạo trang...', 20000)
				await this.activeNewVendor({
					id: this.newClient.vendorId,
					body: {
						...data,
						domain: `${data.domain}.izteach.vn`,
						email: this.newClient.email
					}
				})
				this.$message.success('Tạo trang thành công !')
				this.closeDialog()
				this.$emit('refresh')
			} catch (error) {
				this.$message.error(error)
			}
		},
		async register(data) {
			try {
				this.$message.loading('Đang đăng kí tài khoản...', 20000)
				await this.createNewClient({
					...data,
					password: Base64.encode(data.password)
				})
				this.$message.success('Đăng kí tài khoản thành công !')
				this.step = 3
			} catch (error) {
				this.$message.error('Email hoặc số điện thoại đã tồn tại !')
			}
		},
		closeDialog() {
			this.reset()
		}
	}
}
</script>
