<template>
	<div>
		<v-form ref="form">
			<v-text-field
				dense
				ref="siteName"
				placeholder="Nhập tên website"
				:rules="[$rules.required]"
				v-model.trim="form.siteName"
				@keyup.native.enter="createDomain"
				outlined
			></v-text-field>
			<v-text-field
				dense
				ref="domain"
				placeholder="Nhập tên domain"
				:rules="[$rules.required]"
				v-model.trim="form.domain"
				@keyup.native.enter="createDomain"
				outlined
			>
				<div slot="append" class="pa-1">.izteach.vn</div>
			</v-text-field>
			<v-switch v-model="form.isVendorDRMAllowed" :label="configLabelText" />
		</v-form>
		<div class="d-flex justify-end align-center">
			<BaseButton
				class="elevation-0"
				type="submit"
				text="Tạo"
				@click="createDomain"
				color="primary"
			/>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			form: {
				siteName: '',
				domain: '',
				isVendorDRMAllowed: false
			}
		}
	},
	computed: {
		configLabelText() {
			return this.form.isVendorDRMAllowed
				? 'Cho phép video được encode với key bảo mật'
				: 'Không cho phép video được encode với key bảo mật'
		}
	},
	methods: {
		async createDomain() {
			if (!this.$refs.form.validate()) return
			this.$emit('updateDomainInfo', this.form)
		}
	}
}
</script>
